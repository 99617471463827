import dayjs, { extend } from "dayjs";
import "dayjs/locale/it";
import relativeTime from "dayjs/plugin/relativeTime";
import { graphql, navigate } from "gatsby";
import React, { useMemo } from "react";
import { useTheme } from "styled-components";

import { Heading } from "@source-web/heading";
import { LinkWithIcon } from "@source-web/link-with-icon";
import { Paragraph } from "@source-web/paragraph";
import { Span } from "@source-web/span";

import { ThemedSection } from "../../components/ThemedSection";
import { getDowntimePageInformation } from "../../lib";
import { PageContext } from "../../types";
import * as Styled from "../downtime/styles/downtime.styles";

export const query = graphql`
  query getDowntimePageContent($locale: String) {
    allContentfulDowntimePage(filter: { node_locale: { eq: $locale } }) {
      nodes {
        statement
        heading
        lastCheck
        downTimeMessage
      }
    }
  }
`;

interface DowntimePageContentful {
  heading: string;
  statement: string;
  lastCheck: string;
  downTimeMessage: string;
}

interface IDowntimePage {
  data: {
    allContentfulDowntimePage: {
      nodes: Array<DowntimePageContentful>;
    };
  };
  pageContext: PageContext;
}

interface IDowntimeInformation {
  date: string;
  error: string;
}

extend(relativeTime);

function DowntimePage({ data, pageContext }: IDowntimePage) {
  const { allContentfulDowntimePage } = data;

  const info: IDowntimeInformation = useMemo(
    () => getDowntimePageInformation(),
    []
  );

  const pageContent: DowntimePageContentful | undefined = useMemo(
    () =>
      allContentfulDowntimePage?.nodes?.find(
        (node) => node?.statement === info?.error
      ),

    [allContentfulDowntimePage?.nodes, info?.error]
  );

  const theme = useTheme();

  return (
    <ThemedSection appearance="primary">
      <Styled.DowntimePage>
        <Styled.TextWrapper>
          <Heading
            appearance="primary"
            inverse={theme.name !== "WS10"}
            level={4}
          >
            {pageContent?.heading}
          </Heading>
          <Styled.DowntimePage_InformationContainer>
            {info?.date && (
              <>
                <Paragraph appearance="primary" inverse={theme.name !== "WS10"}>
                  <Span>{pageContent?.lastCheck}</Span>{" "}
                  {dayjs(info.date)
                    .locale(pageContext.formattedLocale)
                    .fromNow()}
                </Paragraph>

                <Styled.DowntimePage_BackButton>
                  <LinkWithIcon
                    appearance="secondary"
                    text={
                      pageContent?.downTimeMessage || "Click here to try again"
                    }
                    icon={{
                      name: "arrow-left",
                      justify: "left"
                    }}
                    onClick={() =>
                      navigate(`/${pageContext.formattedLocale}/signIn`)
                    }
                  />
                </Styled.DowntimePage_BackButton>
              </>
            )}
          </Styled.DowntimePage_InformationContainer>
        </Styled.TextWrapper>
      </Styled.DowntimePage>
    </ThemedSection>
  );
}

export default DowntimePage;
