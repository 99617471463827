import styled, { css } from "styled-components";

import { Theme } from "@source-web/themes";

export const DowntimePage = styled.div`
  min-height: 85vh;
  display: flex;
  overflow-y: hidden;
  justify-content: center;
  flex-direction: column;
  margin: 0 1.25em;
  align-items: center;
`;

export const DowntimePage_InformationContainer = styled.div`
  margin-top: 1.5em;
`;

export const DowntimePage_BackButton = styled.div`
  margin-top: 1.5em;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const TextWrapper = styled.div(
  ({ theme }: { theme: Theme }) => css`
    width: 10em;
    margin-bottom: 1.5em;
    @media (min-width: ${theme.breakpoints.md}px) {
      width: 20em;
    }
  `
);
